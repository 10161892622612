import { Component } from "react";
import { activateUser, disableUser, getChatMessages, getUserChats, getUserDetail, getUserReports } from "../services/service";
import dayjs from "dayjs";

export default class UserDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: null,
            chats: [],
            messages: [],
            reports: [],
            selectedChat: null,
            banReason: '',
            showBanModal: false,
        };
    }

    banUser = async () => {
        const { data } = await disableUser({ id: this.props.match.params.id, reason: this.state.banReason });
        if (data) {
            this.setState({ showBanModal: false, banReason: '' });
            this.fetchUser();
        }
    };

    unbanUser = async () => {
        const { data } = await activateUser({ id: this.props.match.params.id });
        if (data) {
            this.fetchUser();
        }
    };

    fetchUserChats = async () => {
        const { data } = await getUserChats({ id: this.props.match.params.id });
        this.setState({ chats: data });
    };

    fetchReports = async () => {
        const { data } = await getUserReports({ id: this.props.match.params.id });
        this.setState({ reports: data });
    };

    fetchChatMessages = async (chatId) => {
        const { data } = await getChatMessages({ chatId });
        this.setState({ messages: data });
    };

    fetchUser = async () => {
        const { data } = await getUserDetail({ id: this.props.match.params.id });
        this.setState({ user: data, loading: false });
    };

    componentDidMount() {
        this.fetchUser();
        this.fetchUserChats();
        this.fetchReports();
    }

    render() {
        const { loading, user, chats, messages, selectedChat, banReason, showBanModal, reports } = this.state;
        return (
            <main className='px-8 py-8 flex flex-row gap-x-4 w-full'>
                {
                    selectedChat && (
                        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                            <div className='bg-white p-4 rounded-lg shadow-lg min-w-96'>
                                {
                                    messages.length > 0 ? (
                                        <div>
                                            <div className='flex items-center justify-between mb-4'>
                                                <h2 className='text-lg font-bold'>Mesajlar</h2>
                                                <button
                                                    onClick={() => this.setState({ selectedChat: null })}
                                                    className='text-gray-500 px-4 py-2 rounded-md'>
                                                    Kapat
                                                </button>
                                            </div>
                                            <hr className="mb-4" />

                                            {/* Scrollable message list */}
                                            <div className='max-h-80 overflow-y-auto'>
                                                <ul>
                                                    {
                                                        messages.map((message) => (
                                                            <li key={message._id} className='gap-y-4'>
                                                                <div className={
                                                                    message.from === user._id
                                                                        ? 'flex flex-col items-start gap-x-4 justify-end border-gray-200 border p-2 mt-2 rounded-lg text-black w-fit'
                                                                        : 'flex flex-col items-start gap-x-4 justify-start bg-red-500 p-2 mt-2 rounded-lg text-white w-fit'
                                                                }>
                                                                    <span className='text-sm text-white-500'>{
                                                                        message.from === user._id
                                                                            ? user.name
                                                                            : selectedChat.sender._id === message.from ? selectedChat.sender.name : selectedChat.receiver.name
                                                                    }</span>
                                                                    <label className="max-w-sm">{message.message}</label>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    ) :
                                        (
                                            <div>
                                                <div className='flex items-center justify-between mb-4'>
                                                    <h2 className='text-lg font-bold'>Mesajlar</h2>
                                                    <button
                                                        onClick={() => this.setState({ selectedChat: null })}
                                                        className='text-gray-500 px-4 py-2 rounded-md'>
                                                        Kapat
                                                    </button>
                                                </div>
                                                <hr className="mb-4" />

                                                <div className='flex items-center justify-center h-32'>
                                                    <span className='text-lg font-bold'>Mesaj Bulunamadı</span>
                                                </div>
                                            </div>
                                        )
                                }

                            </div>
                        </div>
                    )
                }

                {
                    showBanModal && (
                        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                            <div className='bg-white p-8 rounded-lg shadow-lg'>
                                <h2 className='text-xl font-bold mb-4'>Kullanıcıyı Uzaklaştır</h2>
                                <input
                                    type='text'
                                    value={banReason}
                                    onChange={(e) => this.setState({ banReason: e.target.value })}
                                    placeholder='Sebebi nedir?'
                                    className='border border-slate-200 p-2 w-full mb-4' />
                                <div className='flex items-center justify-end gap-x-4'>
                                    <button
                                        onClick={this.banUser}
                                        className='bg-black text-white px-4 py-2 rounded-md'>
                                        Uzaklaştır
                                    </button>
                                    <button
                                        onClick={() => this.setState({ showBanModal: false })}
                                        className=' text-gray-500 px-4 py-2 rounded-md'>
                                        İptal
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }

                <section className="col-span-full xl:col-span-8 bg-white shadow-sm rounded-lg border border-slate-200 w-fit">
                    {
                        loading ? (
                            <div className='flex items-center justify-center h-32'>
                                <span className='text-lg font-bold'>Yükleniyor...</span>
                            </div>
                        ) : (
                            <div className='gap-y-0.5 p-4 flex flex-col'>
                                <label className='text-sm font-bold'>Adı</label>
                                <span className='text-sm px-4 py-2 border rounded-md my-2'>{user.name}</span>
                                <label className='text-sm font-bold'>E-posta</label>
                                <span className='text-sm px-4 py-2 border rounded-md my-2'>{user.email}</span>
                                <label className='text-sm font-bold'>Telefon</label>
                                <span className='text-sm px-4 py-2 border rounded-md my-2'>{user.phone}</span>
                                <label className='text-sm font-bold'>Doğrulama</label>
                                <span className='text-sm px-4 py-2 border rounded-md my-2'>{user.is_verified ? 'Evet' : 'Hayır'}</span>
                                <label className='text-sm font-bold'>Kayıt Tarihi</label>
                                <span className='text-sm px-4 py-2 border rounded-md my-2'>{
                                    dayjs(user.created_at).format('DD/MM/YYYY HH:mm')
                                }</span>

                                {
                                    user.is_disabled ? (
                                        <div className="flex flex-col">
                                            <label className='text-sm font-bold'>Uzaklaştırma Sebebi</label>
                                            <span className='text-sm px-4 py-2 border rounded-md my-2'>{user.disable_reason}</span>
                                            <button
                                                onClick={this.unbanUser}
                                                className='bg-green-500 text-white px-4 py-2 rounded-md mt-4 w-fit'>
                                                Kullanıcıyı Aktif Et
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={() => this.setState({ showBanModal: true })}
                                            className='bg-black text-white px-4 py-2 rounded-md mt-4 w-fit'>
                                            Kullanıcıyı Uzaklaştır
                                        </button>
                                    )
                                }
                            </div>
                        )
                    }
                </section>

                {
                    loading ? (
                        <div className='flex items-center justify-center h-32'>
                            <span className='text-lg font-bold'>Yükleniyor...</span>
                        </div>
                    ) : (
                        <div className='bg-white rounded-xl pt-4'>
                            <span className="px-4 font-semibold">Mesaj Listesi</span>
                            <table className="mt-4">
                                <thead className='bg-gray-100 text-gray-600'>
                                    <tr>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Alıcı</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Güncelleme Tarihi</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chats.map((chat) => (
                                        <tr key={chat._id}>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {chat.receiver._id === user._id ? chat.sender.name : chat.receiver.name}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{
                                                dayjs(chat.updated_at).format('DD/MM/YYYY HH:mm')
                                            }
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                <button
                                                    onClick={() => {
                                                        this.fetchChatMessages(chat._id);
                                                        this.setState({ selectedChat: chat });
                                                    }}
                                                    className='bg-gray-100 text-black border px-2 py-1.5 rounded-md'>
                                                    Göster
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                }

                {
                    loading ? (
                        <div className='flex items-center justify-center h-32'>
                            <span className='text-lg font-bold'>Yükleniyor...</span>
                        </div>
                    ) : (
                        <div className='bg-white rounded-xl pt-4'>
                            <span className="px-4 font-semibold">Bildirim Listesi</span>
                            <table className="mt-4">
                                <thead className='bg-gray-100 text-gray-600'>
                                    <tr>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Bildiren</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Sebep</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'>Tarih</th>
                                        <th className='border-b border-slate-200 p-4 text-left text-sm font-bold'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports.map((r) => (
                                        <tr key={r._id}>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>
                                                {r.user.name}
                                            </td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{r.reason}</td>
                                            <td className='border-b border-slate-200 p-4 text-left text-sm'>{
                                                dayjs(r.created_at).format('DD/MM/YYYY HH:mm')
                                            }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </main>
        );
    }
}